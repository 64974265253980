body {
    
    font-family: Arial, sans-serif;
   
}

.faq-container {
    background-color: black;
    color: white;
    padding: 20px;
    box-sizing: border-box;
}

.faq-content {
    max-width: 800px;
    margin: 0 auto;
}

.faq-section {
    margin-top: 40px;
}

.faq-item {
    margin-bottom: 20px;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.faq-question-heading {
    font-size: 16px;
    color: white;
}
.faq-answer {
    margin-top: 10px;
}
.faq-heading {
    font-size: 24px;
    /* text-align: center; */
    margin-top: 13%;
    margin-bottom: 8%;
}
/* Style for the gray separator */
.separator {
    height: 1px;
    width: 100%;
    background-color: #ccc;
    margin: 10px 0;
}