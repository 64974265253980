.footer .short-link a,
.footer .help-link a,
.footer .contact-link a {
    transition: .5s;
}

.footer .short-link a:hover,
.footer .help-link a:hover,
.footer .contact-link a:hover {
    letter-spacing: 1px;
}

.footer .hightech-link a:hover {
    background:#E62536;
    border: 0;
}

.footer a {
    text-decoration: none;
  }
 
.footer  .footer-icon {
    color: #E62536
}



.certificate-slides{
    display: grid;
    position: relative;
    overflow: hidden;
  }
  .certificate-slide-track{
    display: flex;
    width: calc(300px*14);
    animation: scroll 20s linear infinite;
  }
  
  .certificate-slide {
    height: 100px;
    width: 200px;
    display: flex;
    align-items: center;
    padding: 15px;
  
  }
  @keyframes scroll {
    0% {transform: translateX(0);}
    100% {transform: translatex(calc(-300px*7))}
  } 
  