#span {
  color: #E62536;
}
 
.carde {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
   }
 
.image_back .image {
  padding-left: 225px;
  width: 47rem;
  height: 20rem;
  padding-top: 40px;
}
 
@media (max-width: 1400px) {
  .image_back .image {
    padding-left: 5px;
 
    width: 30rem;
  }
 
  .image_back {
    padding-top: 150px;
  }
}
 
@media (max-width: 1000px) {
  .image_back .image {
    padding-left: 5px;
    width: 25rem;
  }
}
 
@media (max-width: 990px) {
  .image_back .image {
    padding-left: 5px;
    width: 25rem;
  }
 
  .image_back {
    padding-top: 150px;
  }
}
 
@media (max-width: 766px) {
  .image_back {
    padding-top: 0px;
  }
}
 
@media (max-width: 770) {
  .image_back .image {
    margin-left: 10px;
    width: 10rem;
  }
}
 
@media (max-width: 390px) {
  .image_back .image {
    padding-left: 5px;
    width: 16rem;
  }
 
  .hr-lines {
    font-size: 25px;
  }
 
  .hr-lines:before {
    margin-bottom: 19px;
  }
}
 
 
 
.selected {
  transition: transform 0.5s, filter 0.5s, box-shadow 0.5s;
}
 
.selected:hover {
  transform: scale(1.1);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}