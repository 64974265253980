
.containerT {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 12%;
}

.containerT h1 {
    color: #E62536;
}

.containerT p {
    color: white;
    margin-bottom: 4%;
}

.containerT strong {
    font-weight: bold;
    color:whitesmoke;
}

.containerT a {
    color: #007bff;
    text-decoration: none;
}

.containerT a:hover {
    text-decoration: underline;
} 