.homepage-carousel .carousel-caption {
    position: absolute;
    bottom: 10rem;
    font-size: 3.67rem;
    font-weight: 700;
    align-self: center;
   
}

/* Animation */
.homepage-carousel .carousel-bg {
    height: 650px;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s;
  }
 
  .homepage-carousel .carousel-bg.zoom-in {
    transform: scale(1.1);
  }
  .homepage-carousel .carousel-caption button.read-more-button {
    background-color: #9A0412;
    color: #f5f2f2;
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    border-radius: 50px;
    margin-top: 50px;
  }
  
  .homepage-carousel .carousel-caption button.read-more-button:hover {
    background-color: #0056b3;
  }
  @media(max-width:750px){
    .carousel-captions-tittle{
      font-size: 40px;
      
    }
    .carousel-captions-description{
      font-size: 22px;
    }
  }
  @media(max-width:480px){ 
    .carousel-captions-tittle{
      font-size: 30px;
    }
    .carousel-captions-description{
      font-size: 18px;
    }
  }