section {
    display: block;
  }
  .fixed-header .hero-image.page-title {
    padding-top: 14.6875em;
  }
  .hero-image{
    background-image: url("../../Assets/career-logo.png");
    background-position: 100%;
  }
  .hero-image.page-title {
    color: #fff;
    padding: 10em 20px;
    position: relative;
    text-align: center;
  }
  .fixed-header .hero-image {
    padding-top: 4.6875em;
  }
  @media (max-width: 1250px) {
    .hero-image {
      background-position: 100%;
    }
  }
  .hero-image {
    background-position: top;
    background-size: cover;
    overflow: auto;
  }
  .hero-image.page-title:before {
    background: rgba(0, 0, 0, 0.45);
  }
  
  .hero-image.page-title:before {
    background: rgba(0, 0, 0, 0.35);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
  .hero-image.page-title h1 {
    font-size: 2em;
    letter-spacing: 0.125em;
    margin: 0 auto;
    max-width: 710px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
  }
  .hero-image.page-title:after {
    border-top: 6px solid #C8202F;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
  }
  .overflow {
    overflow: auto;
  }
  .clear:after {
    clear: both;
    content: "";
    display: table;
  }
  .page-content aside {
    margin: 2.8125em 0;
    max-width: 250px;
  }
  
  .float-left {
    float: left;
  }
  .page-content aside nav ul {
    font-family: exo, Helvetica, Arial, sans-serif;
    font-size: 0.75em;
    letter-spacing: 0.25em;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  .page-content aside nav ul .mobile-current {
    display: none;
  }
  
  .page-content aside nav ul li {
    color: #000;
    margin: 0 0 2em;
  }
  .page-content .careers-content {
    background: #fff;
    color: #000;
    margin-left: auto;
    margin-right: auto;
    max-width: 710px;
    padding: 2.8125em;
  }
  .page-content .careers-content a {
    color: #C8202F;
  }
  .careers-content {
    box-shadow: 1px 2px 16px 5px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: 1px 2px 16px 5px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 1px 2px 16px 5px rgba(0, 0, 0, 0.14);
  }
  
  .button.red {
    border-color: #C8202F;
    color: #C8202F !important;
    background: transparent;
  }
  .button.red:hover {
    color: #fff !important;
  
    background: #C8202F;
    transition: 0.4s ease-in-out background;
  }
  .button {
    border-radius: 1.15em 0;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    font-size: 0.75em;
    font-weight: 600;
    letter-spacing: 0.2em;
    margin: 1.5em;
    padding: 0.5em 1.5em;
    text-transform: uppercase;
  }
  .top-border:before {
    border-top: 1px solid #e6e6e6;
    content: "";
    display: inline-block;
    margin: 0 auto;
    max-width: 1250px;
    width: 100%;
  }
  
  .card {
    max-width: 100%;
    padding: 0;
    border: none;
    border-radius: 0.5rem;
  }
  
  .nav-item {
    width: 50%;
  }
  .nav-link {
    font-weight: 500;
    border-radius: 0 !important;
  }
  
  .nav-pills .nav-link.active {
    color: #fff !important;
    background: #C8202F;
    border-radius: 0.5rem 0.5rem 0 0;
    font-weight: 600;
  }
  
  .card ul {
    margin-top: 0 !important;
  }
  .tab-content {
    padding-bottom: 1.3rem;
    background: #f8f8f8;
  }
  .why-wrk-with-us {
    font-weight: 700;
    letter-spacing: 0.125em;
    margin: 0.5em 0 2.5em !important;
    text-align: center;
    text-transform: uppercase;
  }
   .nav-link.active:after{
      border-bottom: 10px solid  #f8f8f8;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      content: "";
      height: 0;
      margin: auto;
      position: relative;
      top: 2px;
      left: -25px;
  
    }
  