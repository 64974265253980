button:not(:disabled) {
    cursor: pointer;
    backdrop-filter: blur(30px);
    color: white;
    text-decoration: none;
    
}
.border {
    border: none !important;
    top:0
}


/* make color white in mobile view drop dwon list */