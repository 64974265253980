.container-product {
  background-size: cover;


  }
  
  .container-product .content-container {

    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .text-container {
    flex: 1;
    margin-top: 5%;
  }
  
  .image-container {
    flex: 1;
    margin-top: 5%;
   
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 985px) {
    .content-container {
      flex-direction: column;
      text-align: center;
    }
  
    .text-container,
    .image-container {
      flex: auto;
      margin: 20px;
    }
  }

  @media (max-width: 768px) {
    .content-container {
      flex-direction: column;
      text-align: center;
    }
  
    .text-container,
    .image-container {
      flex: auto;
      margin: 20px;
    }
  }
  




  .container-cloud-security{
    margin-top: 50px;
   /* background-image: url("../../Assets/vectors.png"); */
   background-size: cover;
   /* background-color: aliceblue; */
}




.ourValues-image {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
 
  }
  
  .ourValues-image img {
    
    width: 50%;
    height: 50%;
  }

 .cloudContainer{
    display: flex; 
    flex-direction:column;
    margin-left: auto;
   
 }
 
.hr-lines-product:before {
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background: #C8202F;
    position: relative;
    right: 60px;
    top: 55px;
    border-radius: 40px;
    
  }
  



  .productBody {
    display: flex;
    justify-content:center;
    align-items: center;
    min-height:100vh;
  }
   .iconProducts{
    width:40%; 
    padding-bottom: 10%;
   }
   .hr-lines-product:before {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background: #C8202F;
    position: relative;
    right: 60px;
    top: 40px;
    border-radius: 40px;
    
  }

   .hr-lines-product{
    margin-left: 100px;
  }
  .sub-heading{
    margin-left: 5rem;
  }
  .cloudContainer-content{
    margin-left: 5rem;
  }
  
  @media (max-width: 900px) {
    .hr-lines-product{
      margin-left: 4rem;
    }
    .sub-heading{
      margin-left: 4rem;
      font-size: 20px;
    }
    .cloudContainer-content{
      margin-left: 4rem;
    }
    
  }
  
  @media (max-width: 700px) {
    .hr-lines-product{
      margin-left: 3rem;
      font-size: 22px;
    }
    .sub-heading{
      margin-left: 3rem;
      font-size: 16px;
    }
    .cloudContainer-content{
      margin-left: 3rem;
    }
    .hr-lines-product:before {
      content: "";
      display: block;
      width: 40px;
      height: 2px;
      background: #C8202F;
      position: relative;
      right: 60px;
      top: 22px;
      border-radius: 40px;
      
    }
  }


  /*** Service ***/
.product-item .product-text {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .06);
  transition: .5s;
  z-index: 2;
}

/* .product-item:hover .product-text {
  top: -1.5rem;
}

.product-item .product-text h5 {
  transition: .5s;
}

.product-item:hover .product-text h5 {
  color: "white";
} */

.product-item .product-btn {
  position: absolute;
  width: 80%;
  height: 3rem;
  left: 10%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
  transition: .5s;
  z-index: 1;
}

.product-item:hover .product-btn {
  bottom: -1.5rem;
}