.loader{
    background-color: black;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
   
}
.loader img {
    max-width: 100%;
    /* Ensure the image doesn't exceed its container width */
    height: auto;
}