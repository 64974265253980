
  

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    line-height: 1.8;
}


.contact_section_start{
    /* padding: 50px 0 0; */
    background-color: #ffff;
    position: relative;
    z-index: 2;
}
.contact_section_start .container{
    /* max-width: 1200px; */
    margin: auto;
    /* padding: 0 25px; */
}
.map{
    width: 30rem;
    height: 300px;
    /* position: sticky; */
    bottom: 0;
  }
.contact_section_start .container .content_wraper{
    display: flex;
    gap: 25px;
}
@media all and (max-width: 850px){
    .contact_section_start .container .content_wraper{
        flex-direction: column;
    }
}

.contact_section_start .container .content_wraper>*{
    width: 50%;
    /* padding: 15px; */
}
@media all and (max-width: 850px){
    .contact_section_start .container .content_wraper>*{
        width: 100%;
    }
}

.content_wraper>.left .section_title{
    margin-bottom: 20px;
    color: #333;
}
.content_wraper>.left .contact_text{
    color: #666;
    font-size: 18px;
    text-align: justify;
    margin-bottom: 15px;
}

.content_wraper>.left .contact_info>a{
    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: underline;
    width: fit-content;
    margin-bottom: 10px;
    text-decoration: none;
    color: #444;
}
.content_wraper>.left .contact_info .icon{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    background-color: #014AAA;
    color: #fff;
    box-shadow: 0 2px 5px #0004;
}

.contact_section_start .container .content_wraper>.right{
    display: grid;
    place-content: center;
    border-left: 1px solid #0001;
}
.contact_section_start .container .content_wraper>.right img{
    width: 100%;
}

@media all and (max-width: 850px){
    .contact_section_start .container .content_wraper>.left{
        order: 2;
    }
    .contact_section_start .container .content_wraper>.right{
        border: none;
    }
}
.contact_info .contact-icon{
    width: 14%;
    height: 14%;
}

.contact_form{
    padding: 2px 2px 0;
    transform: translateY(20px);
    margin-bottom: 100px;
}

.contact_form form{
    box-shadow: 0 0 35px #0001, 0 0 15px #0001, 0 0 5px #0001;
    border-radius: 15px;
    padding: 40px 25px;
    backdrop-filter: blur(15px);
}

.form_control{
    padding: 5px 10px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #0002;
    font-family: inherit;
    font-size: 16px;
    margin-bottom: 35px;
    outline: none;
    transition: 0.3s ease-in-out;
}

.form_control::placeholder{
    color: #000000;
}

.form_control:is(:hover,:focus){
    border-bottom-color: #C11907;
}

.form_flex{
    display: flex;
    gap: 20px;
}

@media all and (max-width: 550px) {
    .form_flex{
        flex-direction: column;
    }
    .map{
        width: 20rem;
        height: 300px;
      }
    
}


.submit_btn{
    border: none;
    background-color: #C11907;
    color: #fff;
    padding: 6px 25px;
    font-size: 18px;
    border-radius: 25px;
    box-shadow: 0 2px 15px #0002, 0 1px 5px #0002;
    text-transform: uppercase;
    display: inline-flex;
    gap: 10px;
    align-items: center;
}

/* .contact-map {
    background: #26d48c;
} */

.contact-form {
    background: #C11907;
    
}

.contact-icon{
width: 42px;
height: 42px;
padding: 10px;
}