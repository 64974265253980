.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.8;
    margin-top: 13%;
    margin-bottom: 5%;
}

.privacy-policy h1,
h2 {
    color: #E62536;
}

.privacy-policy p {
    color: white;
    margin-bottom: 8%;
}

.privacy-policy a {
    color: #007BFF;
    text-decoration: none;
}

.privacy-policy a:hover {
    text-decoration: underline;
}