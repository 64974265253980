.container-aboutus {
  margin-top: 100px;
  background-size: cover;
}

.content-container {
  display: flex;
  justify-content: space-around;
  background-image: url("../../Assets/vectors.png");
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.text-container {
  flex: 1;
}

.image-container {
  flex: 1;
}

/* Media queries for responsiveness */
@media (max-width: 985px) {
  .content-container {
    flex-direction: column;
    text-align: center;
  }

  .text-container,
  .image-container {
    flex: auto;
    margin: 20px;
  }
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    text-align: center;
  }

  .text-container,
  .image-container {
    flex: auto;
    margin: 20px;
  }
}





.container-about {
  background-image: url("../../Assets/vectors.png");
  background-size: cover;

}




.ourValues-image {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}


@media (max-width: 1400px) {
  .image-container .image {
    padding-left: 5px;

    width: 30rem;
  }

  .image-container {
    padding-top: 150px;
  }
}

@media (max-width: 1000px) {
  .image-container .image {
    padding-left: 5px;
    width: 25rem;
  }
}

@media (max-width: 990px) {
  .image-container .image {
    padding-left: 5px;
    width: 25rem;
  }

  .image-container {
    padding-top: 150px;
  }
}

@media (max-width: 766px) {
  .image-container {
    padding-top: 0px;
  }
}

@media (max-width: 770) {
  .image-container .image {
    margin-left: 10px;
    width: 10rem;
  }

}

@media (max-width: 390px) {
  .image-container .image {
    padding-left: 5px;
    width: 16rem;
  }

  .hr-lines {
    font-size: 20px;
  }


}


.testimonial-area {
  justify-content: space-around;
  background-image: url("../../Assets/vectors.png");
  align-items: center;
  margin: 0 auto;
}

.testimonial-area {

  .section-heading h2 {
    font-size: 48px;
    line-height: 58px;
  }
}

.testi-wrap {
  position: relative;
  height: 525px;
  margin-top: -80px;
}

.client-single {
  margin-top: 20px;
  text-align: center;
  position: absolute;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.client-info,
.client-comment {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.client-single {
  &.inactive {

    .client-comment,
    .client-info {
      display: none;
    }

    .client-comment,
    .client-info {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.position-1 {
    -webkit-transform: scale(0.65);
    transform: scale(0.65);
  }

  &.position-2 {
    left: -40px;
    top: 185px;
  }

  &.position-3 {
    left: -60px;
    top: 240px;
    -webkit-transform: scale(0.4) !important;
    transform: scale(0.4) !important;
  }

  &.position-4 {
    left: 55px;
    top: 380px;
  }

  &.position-5 {
    top: 30px;
    right: 55px;
  }

  &.position-6 {
    top: 225px;
    right: -40px;
  }

  &.position-7 {
    top: 400px;
    right: 45px;
    -webkit-transform: scale(0.4) !important;
    transform: scale(0.4) !important;
  }

  &.active {
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10;
    width: 70%;

    .client-comment,
    .client-info {
      -webkit-transition-delay: 0.6s;
      transition-delay: 0.6s;
    }
  }

  &:not(.active) {
    -webkit-transform: scale(0.55);
    transform: scale(0.55);
    z-index: 99;
  }

  &.active .client-img {
    width: 160px;
    height: 160px;
    margin: 0 auto 24px;
    position: relative;

    &:before {
      border-radius: 100%;
      content: '';
      padding: 5px;
      width: 160px;
      height: 160px;
      top: -4px;
      left: 0px;
      position: absolute;
      z-index: -1;
    }
  }

  .client-img img {
    width: 250px;
    background-position: center;
    padding: auto;
    cursor: pointer;
  }

  &.active .client-img img {
    max-width: 160px;
    margin: 0 auto 24px;
    border: 0;
  }
}

.client-comment {
  padding: 0 30px;

  h3 {
    font-size: 22px;
    line-height: 32px;
    color: #505b6d;
  }

  span i {
    font-size: 60px;
    color: #0084ff;
    margin: 40px 0 24px;
    display: inline-block;
  }
}

.client-info {
  h3 {
    color: #000;
    font-weight: 600;
    margin-bottom: 4px;
  }

  p {
    color: #0084ff;
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-area .section-heading h2 {
    font-size: 30px;
  }

  .client-comment h3 {
    font-size: 18px;
    line-height: 28px;
  }

  .client-single.active {
    width: 60%;
  }

  .client-single:not(.active) {
    -webkit-transform: scale(0.55);
    transform: scale(0.35);
  }

  .client-single.position-3,
  .client-single.position-7 {
    -webkit-transform: scale(.30) !important;
    transform: scale(.30) !important;
  }

  .client-single.active .client-img img {
    max-width: 100px;
  }

  .client-single.active .client-img::before {
    padding: 5px;
    width: 108px;
    height: 108px;
    top: -4px;
    left: 6px;
  }

  .client-single.active .client-img {
    width: 120px;
    height: 100px;
  }

  .testi-wrap {
    height: 580px;
  }

  .testimonial-area {
    padding: 100px 0 0;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .testimonial-area .section-heading h2 {
    font-size: 30px;
  }

  .client-comment h3 {
    font-size: 14px;
    line-height: 26px;
  }

  .client-single.active {
    width: 60%;
  }

  .client-comment span i {
    font-size: 40px;
  }

  .client-single:not(.active) {
    -webkit-transform: scale(0.55);
    transform: scale(0.35);
  }

  .client-single.position-5,
  .client-single.position-7 {
    right: 0;
  }

  .client-single.position-4 {
    right: 0;
  }

  .client-single.position-3,
  .client-single.position-7 {
    -webkit-transform: scale(.30) !important;
    transform: scale(.30) !important;
  }

  .client-single.active .client-img img {
    max-width: 80px;
  }

  .client-single.active .client-img::before {
    padding: 5px;
    width: 88px;
    height: 88px;
    top: -4px;
    left: 16px;
  }

  .client-single.active .client-img {
    width: 120px;
    height: 100px;
  }

  .testi-wrap {
    height: 630px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .testimonial-area .section-heading h2 {
    font-size: 30px;
    line-height: 40px
  }

  .client-comment h3 {
    font-size: 14px;
    line-height: 26px;
  }

  .client-single.active {
    width: 80%;
  }

  .client-comment span i {
    font-size: 40px;
  }

  .client-single:not(.active) {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
  }

  .client-single.position-5,
  .client-single.position-7,
  .client-single.position-6 {
    right: -70px;
  }

  .client-single.position-4 {
    right: -70px;
  }

  .client-single.position-3 {
    left: -75px;
  }

  .client-single.position-3,
  .client-single.position-7 {
    -webkit-transform: scale(.25) !important;
    transform: scale(.25) !important;
  }

  .client-single.active .client-img img {
    max-width: 80px;
  }

  .client-single.active .client-img::before {
    padding: 5px;
    width: 88px;
    height: 88px;
    top: -4px;
    left: 16px;
  }

  .client-single.active .client-img {
    width: 120px;
    height: 100px;
  }

  .testi-wrap {
    height: 600px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
  .testimonial-area .section-heading h2 {
    font-size: 30px;
  }

  .client-comment h3 {
    font-size: 14px;
    line-height: 26px;
  }

  .client-single.active {
    width: 80%;
  }

  .client-comment span i {
    font-size: 40px;
  }

  .client-single:not(.active) {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
  }

  .client-single.position-5,
  .client-single.position-7,
  .client-single.position-6 {
    right: -70px;
  }

  .client-single.position-4 {
    left: -60px;
  }

  .client-single.position-3 {
    left: -75px;
  }

  .client-single.position-3,
  .client-single.position-7 {
    -webkit-transform: scale(.25) !important;
    transform: scale(.25) !important;
  }

  .client-single.active .client-img img {
    max-width: 80px;
  }

  .client-single.active .client-img::before {
    padding: 5px;
    width: 88px;
    height: 88px;
    top: -4px;
    left: 16px;
  }

  .client-single.active .client-img {
    width: 120px;
    height: 100px;
  }

  .testi-wrap {
    height: 550px;
  }
}

.about-heading .hr-lines {
  margin-left: 100px;
}

@media (max-width: 900px) {
  .about-heading .hr-lines {
    margin-left: 2rem;
  }
}

@media (max-width: 700px) {
  .about-heading .hr-lines {
    margin-left: 3rem;
    font-size: 22px;
  }

  .about-heading .hr-lines:before {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background: #00C389;
    position: relative;
    right: 60px;
    top: 22px;
    border-radius: 40px;

  }
}


.partners-slides{
  display: grid;
  width: 90%;
  position: relative;
  overflow: hidden;
}
.slide-track{
  display: flex;
  width: calc(300px*10);
  animation: scroll 20s linear infinite;
}

.partners-slide {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  padding: 15px;

}
@keyframes scroll {
  0% {transform: translateX(0);}
  100% {transform: translatex(calc(-300px*5))}
} 


@media (max-width: 750px) {
  .testi-wrap {
    display: none;
    /* Hide the testi-wrap on smaller screens */
  }
}

@media (min-width: 750px) {
  .partners-slide {
    display: none;
    /* Hide the testi-wrap on smaller screens */
  }
}




/* ######## Our locations######## */

.container-locations {
  display: flex;
  flex-direction: column;
}

.container-locations nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 10px;
}

.container-locations ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.container-locations li {
  margin-right: 20px;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.container-locations li.active {
  font-weight: bold;
  border-bottom: 4px solid #C8202F;
}