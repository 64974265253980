

@font-face {
  font-family: 'Poppins';
  src: url('./Assets/fonts/Poppins-Regular.ttf') format('truetype');
}
body {
  font-family: 'Poppins', sans-serif;
  background-color: #161617;
  color: white;
}

.hr-lines:before {
  content: "";
  display: block;
  width: 50px;
  height: 2px;
  background: #C8202F;
  position: relative;
  right: 60px;
  top: 55px;
  border-radius: 40px;
}

.hr-lines {
  font-style: normal;
  font-weight: 400;
  font-size: 3.375rem;
  padding-bottom: 40px;
  padding-top: 60px;
  padding-left: 60px;
}

@media all and (max-width: 601px) {
  .hr-lines:before {
    content: "";
    display: block;
    width: 30px;
    height: 2px;
    background: #C8202F;
    position: relative;
    right: 40px;
    top: 35px;
    border-radius: 40px;
    
  }
  
  .hr-lines {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    padding-bottom: 40px;
    padding-top: 60px;
    padding-left: 60px;
  }
  
}