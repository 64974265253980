/* ===============CTO SERVICE================= */

.our-service .our-service-heading{
  color: #C8202F;
}
.our-service .our-service-icon{
  color: #004aac;
}
.our-service .our-service-subheading{
  color: #C8202F;
}


  .serviceBody {
    display: flex;
    justify-content:center;
    align-items: center;
    min-height:100vh;
    background: #161623;
    

    
  }
.container-service{
  background: #161623;
}
  .service-hr{
    margin-top: 50px;
    background: #161623;
    padding-left: 100px;
  }
  .serviceBody::before{
    content:'';
    position:absolute;
    background-size: cover ;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background:linear-gradient(#c7d0d6,#C8202F);
    clip-path:circle(30% at right 70%);
    animation: pulse 30s ease-in-out infinite alternate;
 
  }

  @keyframes pulse {
    0% {
      clip-path: circle(10% at right 90%);
    }
   
    50% {
      clip-path: circle(30% at right 70%);
    }
   
    100% {
      clip-path: circle(50% at right 70%);
    }}


  .containerService
  {
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-wrap:wrap;
    z-index:1;
    width: 100%;
    /* margin-top: 100px; */
  }
  .containerService .cardService
  {
    position:relative;
    width:280px;
    height:280px;
    margin:30px;
    
    box-shadow:20px 20px 50px rgba(0,0,0,0.5);
    border-radius:15px;
    background:rgba(255,255,255,0.1);
    overflow:hidden;
    display:flex;
    justify-content:center;
    align-items:center;
    border-top:1px solid rgba(255,255,255,0.5);
    border-left:1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
  }
   

   
  .containerService .cardService .content
  {
    
    padding:20px;
    text-align:center;
    transform:translateY(100px);
    opacity:1;
    transition:0.5s;
   
    
  }
  .containerService .cardService:hover .content
  {
    transform:translateY(0px);
    opacity:1;
    padding-bottom: 70px;
  }
  .containerService .cardService .content h2
  {
    position:absolute;
    top:-80px;
    right:30px;
    font-size:8em;
    color: rgba(255,255,255,0.05);
    pointer-events:none;
  }
  .containerService .cardService .content h3{
    font-size:1.8em;
    color:#fff;
    /* z-index:1; */
    padding-bottom: 5%;
  }
  .containerService .cardService .content p{
    font-size:1em;
    color:#fff;
    font-weight:300;
  }
  .containerService .cardService .content a
  {
    position: relative;
    display: inline-block;
    padding:8px 20px;
    margin-top: 15px;
    background:#fff;
    color:#000;
    border-radius:20px;
    text-decoration:none;
    font-weight:500;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  }

  .iconServices{
    width:10%; 
    padding-bottom: 3%;
  }